<template>
  <!-- 登录页 -->
  <el-container class="login-layout">
    <!-- 主要内容 -->
    <el-main>
      <!-- <div class="login-main"> -->
      <!-- logo -->
      <img
        src="@/assets/images/common/logo.png"
        alt=""
        @click="router.push('/search')"
      />
      <!-- 表单 -->
      <div class="form-wrapper">
        <div class="form-title">欢迎使用锐研数据</div>
        <el-tabs v-model="state.activeName">
          <el-tab-pane label="账号登录" name="account">
            <el-form
              :model="loginForm"
              :rules="loginFormRules"
              ref="loginFormRef"
            >
              <el-form-item prop="loginName">
                <el-input
                  v-model="loginForm.loginName"
                  placeholder="请输入邮箱/手机号"
                  type="text"
                  prefix-icon="el-icon-user-solid"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="loginForm.password"
                  placeholder="请输入密码/验证码"
                  type="password"
                  prefix-icon="el-icon-lock"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="loginForm.rememberMe">
                  记住我
                </el-checkbox>
                <span class="password" @click="getPassword">忘记密码</span>
              </el-form-item>
              <el-form-item>
                <el-button
                  :disabled="state.isLoginBtnDisabled"
                  style="width: 100%"
                  type="primary"
                  @click="submitForm('loginFormRef')"
                >
                  {{ state.loginBtnName }}
                </el-button>
              </el-form-item>
              <p style="display: flex; justify-content: center">
                <span>
                  还没有账号？
                  <span
                    @click="toRegister"
                    style="color: #f08300; cursor: pointer"
                  >
                    立即注册！
                  </span>
                </span>
              </p>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
    <!-- 侧边栏 -->
    <el-aside width="420px" class="hidden-xs-only">
      <!-- <div class="content">
        <div class="intro">
          全面集成在线直播、视频课程、考试测评、案例教学、实践教学、实验教学、实习管理等各类应用，提供面向社会科学的互联网在线教育平台
        </div>
        <img src="@/assets/imgs/login/login.png" alt="" />
      </div> -->
    </el-aside>
  </el-container>
</template>
<script setup>
// import store from "@/store";
// import { useRoute, useRouter } from "vue-router";
import { ref, reactive, toRefs, inject, onMounted } from "vue";
// import { httpPostToken } from "@/api/httpService";
// // import crypto from "crypto";
// // import { getDefaultOrgInfo, smartsowoLogin, Debounce } from "@/tool/index";
// // import { AraleQRCode } from "@/tool/qrcode";

// const router = useRouter();
// const route = useRoute();
// /**数据 start */
const state = reactive({
  activeName: "account", //选中的tab
  isLoginBtnDisabled: false, //是否禁用登陆按钮
  loginBtnName: "登录",
  isLoginbtnLoading: false,
});
const loginForm = reactive({
  loginName: "",
  password: "",
  rememberMe: true,
}); //表单
const loginFormRules = {
  loginName: [
    {
      required: true,
      message: "请输入手机号/用户名/邮箱",
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: "请输入密码",
      trigger: "blur",
    },
  ],
}; //表单规则
// const loginFormRef = ref(null);
// function toRegister() {
//   router.push("/register");
// }
// function getPassword() {
//   window.open("https://www.ringdata.com/findpass");
// }

// // const createQrCode = () => {
// //   let codeFigure = new AraleQRCode({
// //     render: "svg", // 生成的类型 'svg' or 'table'
// //     text: "https://www.baidu.com/", // 需要生成二维码的链接
// //     size: 140, // 生成二维码大小
// //     foreground: "#000000" // 二维码颜色
// //   });
// //   let share_tools = document.querySelector("#qrcodeImg");
// //   // 先清空之前的二维码，在填充新的二维码
// //   share_tools.appendChild(codeFigure);
// // };

// /**点击登录 */
// const submitForm = Debounce(function () {
//   loginFormRef.value.validate((valid) => {
//     if (valid) {
//       let ip = window.localStorage.getItem("ip");
//       let city = window.localStorage.getItem("city");
//       let client = "SW";
//       state.isLoginBtnDisabled = true;
//       state.isLoginbtnLoading = true;
//       state.loginBtnName = "登录中";
//       if (loginForm.password.toString().length != 32) {
//         const md5 = crypto.createHash("md5");
//         md5.update(loginForm.password);
//         loginForm.password = md5.digest("hex");
//       }

//       httpPostToken(
//         "/oauth/token",
//         `grant_type=password&username=${loginForm.loginName}&password=${loginForm.password}&clientType=${client}&ip=${ip}&city=${city}`
//       )
//         .then(async (res) => {
//           if (res && res.code === 0) {
//             //缓存用户名密码
//             if (loginForm.rememberMe) {
//               window.localStorage.setItem(
//                 "loginName",
//                 loginForm.loginName
//               );
//               window.localStorage.setItem(
//                 "userPassword",
//                 loginForm.password
//               );
//             } else {
//               window.localStorage.setItem("loginName", "");
//               window.localStorage.setItem("userPassword", "");
//             }
//             //智社登录
//             await smartsowoLogin();
//             getDefaultOrgInfo().then((res) => {
//               if (route.query.from) {
//                 window.location.href = decodeURI(route.query.from);
//               } else {
//                 router.push("/");
//               }
//             });
//             store.commit("setLoginStatus", {
//               status: true,
//             });
//             await store.dispatch("getNumRemind");
//           } else if (res) {
//             state.isLoginBtnDisabled = false;
//             state.isLoginbtnLoading = false;
//             state.loginBtnName = "登录";
//             // this.$message.error(res.message);
//           }
//         })
//         .catch((err) => {
//           state.isLoginBtnDisabled = false;
//           state.isLoginbtnLoading = false;
//           state.loginBtnName = "登录";
//         });
//     } else {
//       return false;
//     }
//   });
// });
// onMounted(() => {
//   // createQrCode();
//   loginForm.loginName = window.localStorage.getItem("loginName");
//   loginForm.password = window.localStorage.getItem("userPassword");
// });
</script>
<style lang="scss" scoped>
// @import "@/assets/styles/display/index.scss";
.login-layout {
  min-height: 100vh;
  .el-main {
    background-color: white;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: absolute;
      left: 20px;
      top: 14px;
      height: 30px;
      cursor: pointer;
    }
    .form-wrapper {
      width: 320px;
      height: 420px;
      background: #fff;
      padding: 30px 20px;
      border-radius: 5px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      .form-title {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
      }
      .password {
        position: absolute;
        right: 20px;
        // color: $primary-color;
        cursor: pointer;
      }
    }
  }
  .el-aside {
    background-color: #e8872c;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-wrap: wrap;
    // padding: 0 68px;
    // .content {
    //   display: flex;
    //   flex-wrap: wrap;
    //   height: 300px;
    //   width: 100%;
    //   .intro {
    //     width: 100%;
    //     color: white;
    //     font-size: 18px;
    //   }
    //   img {
    //     width: 100%;
    //   }
    // }
  }
}
</style>
